// 
// authentication.scss
//


// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

// auth 2

.auth-logo{
    .auth-logo-dark{
        display: $display-block;
    }
    .auth-logo-light{
        display: $display-none;
    }
}

.auth-body-bg{
    background-color: $card-bg;
}

.btn-primary {
    color: $white;
    background-color: $primary-dark-color;
    border-color: $primary-dark-color;

    &:hover {
        color: $white;
        background-color: rgba($primary-dark-color, .95);
        border-color: rgba($primary-dark-color, .95);
    }
}

.btn-danger {
    color: $white;
    background-color: $btn-danger-color;
    border-color: $btn-danger-color;
}

.bg-auth-image{
    background-image: url("../../../../assets/images/auth-1.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.text-primary{
    color: $white!important;
}

.page-item.active {
    .page-link {
        z-index: 3; 
        color: $white;
        background-color: $primary-dark-color;
        border-color: $primary-dark-color;
        border-radius: 50%;
        width: 37px;
        height: 37px;
        text-align:center;
        margin-left:3px;
        margin-right: 3px;
    }
}

.dataTables_filter {

    label {
        input {
            border-radius: 50px;
            height: 36.53px;
            width: 200px;
        }
    }
}


.paginate_button.page-item {
    .page-link {
        border: none;
        border-radius: 50%;
        width: 37px;
        height: 37px;
        margin-left: 3px;
        margin-right: 3px;
    }
}

.paginate_button.page-item.previous {
    .page-link {
        border: none;
        border-radius: 50%;
    }
}

.paginate_button.page-item.next {
    .page-link {
        border: none;
        border-radius: 50%;
        text-align: center;
    }
}
/**/
// auth-pass-inputgroup
.auth-pass-inputgroup {
    input[type="input"] + .btn .mdi-eye-outline {
        &:before {
            content: "\F06D1";
        }
    }
}

// authentication full page

.auth-full-bg{
    background-color: rgba($primary, 0.25);
    display: flex;

    @media (min-width: 1200px){
        height: 100vh;
    }
    

    &::before{
        content: "";
        position: absolute;
        width: 300px;
        height: 300px;
        border-radius: 50%;
    }

    .bg-overlay{
        background: url("../images/bg-auth-overlay.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.auth-full-page-content{
    display: flex;

    @media (min-width: 1200px){
        min-height: 100vh;
    }
}

.auth-review-carousel{
    &.owl-theme {
        .owl-dots {
            .owl-dot{
                span{
                    background-color: rgba($primary, 0.25);
                }
                &.active, &:hover{
                    span{
                        background-color: $primary;
                    }
                }
            }
        }
    }
}